import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import axios from 'axios';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Image from '../components/Image';

const CATEGORY = [{en:'HOKAIDO',place:'北海道'},{en:'TOHOKU',place:'東北'},{en:'KANTO',place:'関東'},{en:'KOUSHINETSU',place:'甲信越'},{en:'HOKURIKU',place:'北陸'},{en:'CHUBU',place:'中部'},{en:'KANSAI / CHUGOKU',place:'関西/中国'},{en:'SHIKOKU',place:'四国'},{en:'KYUSHU',place:'九州'}];

const AgenciesPage = () => {
  const GOODS_ENDPOINT = '/update/json/agencies.json';
  const [ goodsList, setgoodsList ] = useState([]);
  const [Loding, setLoding] = useState(false);

  const goodsFetch = () => {
    axios.get(GOODS_ENDPOINT,{
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json'
    }).then((result) => {
      const { data } = result.data;
      setgoodsList(data);
      setLoding(true);
    }).catch(() => {
      setLoding(false);
    });
  };

  const renderContent = () => {
    if (Object.keys(goodsList).length === 0) return (
        <p className="attention en text-3xl">Not Found</p>
    );

    const list = [];

    CATEGORY.forEach( (value) => {
      const catName = value;
      const catList = goodsList.filter(goods => goods.area === catName.en);
      list.push(
          <div key={value.en} id={value.en} className="content-block">
            <h3 className="sub-title">{catName.en}<span>{catName.place}</span></h3>
            {
              catList.map((goods) =>{
                const {id, agencyName, address, tel, url} = goods;
                return (
                    <a
                        key={id}
                        href={url}
                        className={`text-link max white${!Object.keys(url).length ? ' pointer-events-none':''}`}
                        target="_blank" rel="noreferrer"
                    >
                      <div className="md:flex w-full">
                        <p className="md:w-2/5">{agencyName}</p>
                        <p className="md:w-2/5">{address}</p>
                        <p className="md:w-1/5 md:text-right">{tel}</p>
                      </div>
                    </a>
                );
              })
            }
          </div>
      );
    });
    return list;
  };
  useEffect(() => {
    goodsFetch();
  }, []);

  return (
      <Layout page="agencies-page">
        <Seo title="STORE"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="main-title"><span>STORE</span></h2>
        </section>
        <section className="page-content flex-layout">
          <div className="main-content">
            <div className="content-block">
              <h3 className="sub-title"><span>正規代理店一覧</span></h3>
              <p>アンデルセンストーブ製品を取扱う正規代理店をご紹介します。<br />
                店舗をクリックしますと、各店舗の公式ホームページへと移動します。</p>
            </div>
            { Loding ? renderContent() : null }
            <div className="content-block">
              <Link className="btn full white" to="/showroom/" id="showroom">
                ショールームのご案内
              </Link>
            </div>
          </div>
          <div className="side-list">
        <ul className="sticky">
              <li>
                <GoLink to="HOKAIDO" spy smooth duration={800}>
                  北海道
                </GoLink>
              </li>
              <li>
                <GoLink to="TOHOKU" spy smooth duration={800}>
                  東北
                </GoLink>
              </li>
              <li>
                <GoLink to="KANTO" spy smooth duration={800}>
                  関東
                </GoLink>
              </li>
              <li>
                <GoLink to="KOUSHINETSU" spy smooth duration={800}>
                  甲信越
                </GoLink>
              </li>
              <li>
                <GoLink to="HOKURIKU" spy smooth duration={800}>
                  北陸
                </GoLink>
              </li>
              <li>
                <GoLink to="CHUBU" spy smooth duration={800}>
                  中部
                </GoLink>
              </li>
              <li>
                <GoLink to="KANSAI / CHUGOKU" spy smooth duration={800}>
                  関西/中国
                </GoLink>
              </li>
              <li>
                <GoLink to="SHIKOKU" spy smooth duration={800}>
                  四国
                </GoLink>
              </li>
              <li>
                <GoLink to="KYUSHU" spy smooth duration={800}>
                  九州
                </GoLink>
              </li>
              <li>
                <GoLink className="c-yellow" to="showroom" spy smooth duration={800}>
                  ショールームのご案内
                </GoLink>
              </li>
            </ul>
          </div>
        </section>

        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>正規代理店一覧</li>
          </ul>
        </section>
      </Layout>
  );
};

export default AgenciesPage;
